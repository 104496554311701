import { useEffect, useState } from 'react'
import { ListBox } from '../../components'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { useHouseStore } from '../../stores'
import { useNavigate } from 'react-router-dom'
import { NDataOptionModal } from '../../monorep'

const HouseList = () => {

    const navigate = useNavigate()

    const houseStore = useHouseStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        houseStore.getHouse(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => houseStore.getHouses())
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          name={'Houses'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', furnishing: 'Furnishing Type', views: 'Views', wishlists: 'Wishlisted By', city: 'City', for: 'Rent/Sale' ,status: 'Status' }}
          data={houseStore.houses}
          meta={houseStore.meta}
          primary={['id']}
          handleRefreshButton={() => houseStore.getHouses()}
          onRowClick={(data: any) => handleRowClick(data.id)}
          handleAddButton={() => navigate('/property/houses/add')}
        >
            {showOptionModal && <NDataOptionModal
              visible={showOptionModal}
              setVisible={setShowOptionModal}
              primary={houseStore.house.id}
              options={
                [
                    [
                        { value: titleCase(houseStore.house.propertyStatus), color: getColorByStatus(houseStore.house.propertyStatus), disabled: true }
                    ],
                    [
                        { title: 'Update', color: 'info', icon: 'fa-pencil', disabled: houseStore.house.propertyStatus === 'listed' ? false : true, onClick: (id: string) => navigate('/property/houses/:id/update'.replace(':id', id)) },
                        { title: 'View', color: 'warning', icon: 'fa-eye' }
                    ]
                ]
              }
            />}
        </ListBox>
    )
}

export default HouseList