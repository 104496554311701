import { AxiosError } from 'axios'
import { APIError, CommonAPIRequestFilter } from '../../@types'
import { HouseListResponse, HouseRepository, HouseResponse } from './interface'
import { HouseAPIPayload, HouseAPIResponse, HouseListAPIResponse } from './model'
import { APIService } from '@bigbroindia/barebone'
import { houseListMap } from './mapper'

async function getHouseList(filters?: CommonAPIRequestFilter): Promise<HouseListResponse> {
    try {
        const response: HouseListAPIResponse = await APIService.get('/a/houses/my-properties', filters)
        return {
            data: houseListMap(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('House list api error', error)
        return {
            data: null,
            error: error?.message
        }
    }
}

async function getHouse(id: string): Promise<HouseResponse> {
    try {
        const response = await APIService.get(`/a/houses/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('House api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function postHouse(payload: HouseAPIPayload): Promise<HouseResponse> {
    try {
        const response: HouseAPIResponse = await APIService.post('/a/houses', payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('House post api error', error)
        return {
            data: null,
            error: error?.message
        }
    }
}

async function putHouse(id: string, payload: HouseAPIPayload): Promise<HouseResponse> {
    try {
        const response = await APIService.put(`/a/houses/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('House put api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const houseRepository: HouseRepository = {
    getHouseList,
    getHouse,
    postHouse,
    putHouse
}