import { useNavigate } from "react-router-dom"
import { useCityStore, usePgStore, useStateStore } from "../../stores"
import { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { NButton } from "@nanobits/react-components"
import { DescriptionInput, FieldSet, NTextInput, SelectInput } from "../../monorep"
import { PgAPIPayload } from "../../models/pgs/model"

interface PgFormInterface {
    formType?: 'create' | 'update'
}

const PgForm = ({
    formType
}: PgFormInterface) => {

    const navigate = useNavigate()

    const pgStore = usePgStore()
    const stateStore = useStateStore()
    const cityStore = useCityStore()

    const [state_id, setStateId] = useState<number>()
    const [city_id, setCityId] = useState<number>()
    const [address, setAddress] = useState<string>()
    const [rent, setRent] = useState<number>()
    const [security, setSecurity] = useState<number>()
    const [seater, setSeater] = useState<number>()
    const [mess, setMess] = useState<boolean>(false)
    const [parking, setParking] = useState<boolean>(false)
    const [description, setDescription] = useState<string | undefined>()
    const [project_name, setProjectName] = useState<string>()

    const handleSelectState = (value: number) => {
        setCityId(undefined)
        setStateId(value)
        cityStore.getCitiesDropdown({ state_id: value })
    }

    const handleSubmit = () => {
        const postData: PgAPIPayload = {
            city_id: Number(city_id),
            address: String(address),
            rent: Number(rent),
            security: Number(security),
            seater: Number(seater),
            mess,
            parking,
            description: String(description),
            project_name: String(project_name)
        }

        if (formType === 'create') {
            pgStore.postPg(postData, () => navigate('/property/pgs'))
        } else {
            pgStore.putPg(String(pgStore.pg.id), postData, () => navigate('/property/pgs'))
        }
    }

    useEffect(() => {
        if (formType === 'update' && pgStore.pg.id === undefined) {
            navigate('/property/pgs')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pgStore.pg.id, formType])

    useEffect(() => {
        const timeout = setTimeout(() => stateStore.getStatesDropdown({ country_id: 1 }))
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (formType === 'update') {
            cityStore.getCitiesDropdown({ state_id: state_id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id, formType])

    useEffect(() => {
        if (formType === 'update') {
            setStateId(pgStore.pg.city.stateId)
            setCityId(pgStore.pg.cityId)
            setAddress(pgStore.pg.address)
            setRent(pgStore.pg.rent)
            setSecurity(pgStore.pg.security)
            setSeater(pgStore.pg.seater)
            setMess(pgStore.pg.mess)
            setParking(pgStore.pg.parking)
            setDescription(String(pgStore.pg.description))
            setProjectName(pgStore.pg.projectName)
        }
    }, [pgStore, formType])

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader className={'bg-primary text-white'} >
                        {formType === 'create' ? 'Create Pg' : `Update Pg: ${pgStore.pg.projectName}`}&nbsp;
                        <NButton
                            className={'float-end mx-1 btn-success text-white'}
                            size={'sm'}
                            buttonIconLeft={'fa fa-bars'}
                            onClick={() => navigate('/property/pgs')}
                            loading={pgStore.loading}
                        />
                    </CardHeader>
                    <CardBody>
                        <FieldSet
                            label={'Location Information'}
                        >
                            <Row>
                                <Col className={'mb-3'} md={6}>
                                    <SelectInput
                                        name={'state'}
                                        label={'State'}
                                        placeholder={'Select state'}
                                        options={stateStore.dropdown}
                                        value={state_id}
                                        onUpdate={(value) => handleSelectState(Number(value))}
                                        required
                                    />
                                </Col>
                                <Col className={'mb-3'} md={6}>
                                    <SelectInput
                                        name={'city'}
                                        label={'City'}
                                        placeholder={'Select city'}
                                        options={cityStore.dropdown}
                                        onUpdate={(value) => setCityId(Number(value))}
                                        value={city_id}
                                        required
                                    />
                                </Col>
                                <Col className={'mb-3'} md={12}>
                                    <DescriptionInput
                                        name={'address'}
                                        label={'Address'}
                                        placeholder={'Enter property address'}
                                        value={address}
                                        onUpdate={setAddress}
                                        required
                                    />
                                </Col>
                            </Row>
                        </FieldSet>
                        <FieldSet
                            label={'Property Information'}
                        >
                            <Row>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        name={'project_name'}
                                        label={'Project Name'}
                                        placeholder={'Enter project name'}
                                        required
                                        value={project_name}
                                        onUpdate={setProjectName}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        name={'rent'}
                                        label={'Rent'}
                                        placeholder={'Enter property rent'}
                                        required
                                        value={rent}
                                        onUpdate={setRent}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        name={'security'}
                                        label={'Security'}
                                        placeholder={'Enter pg security here'}
                                        required
                                        value={security}
                                        onUpdate={setSecurity}
                                    />
                                </Col>
                                <Col className={'mb-3'} md={3}>
                                    <NTextInput
                                        name={'seater'}
                                        label={'Seater'}
                                        placeholder={'Pg seater option'}
                                        required
                                        value={seater}
                                        onUpdate={setSeater}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className={'mb-3'}>
                                    <DescriptionInput
                                        name={'description'}
                                        label={'Description'}
                                        placeholder={'Enter property description'}
                                        value={description}
                                        onUpdate={setDescription}
                                    />
                                </Col>
                            </Row>
                        </FieldSet>
                        <FieldSet
                            label={'Amenities Information'}
                        >
                            <Row>
                                <Col md={6} className={'mb-3'}>
                                    <NButton
                                        buttonText={'Parking'}
                                        color={parking ? 'success' : 'danger'}
                                        className={`text-white w-100 ${parking ? '' : 'text-decoration-line-through'}`}
                                        onClick={() => setParking((value) => !value)}
                                    />
                                </Col>
                                <Col md={6} className={'mb-3'}>
                                    <NButton
                                        buttonText={'Mess'}
                                        color={mess ? 'success' : 'danger'}
                                        className={`text-white w-100 ${mess ? '' : 'text-decoration-line-through'}`}
                                        onClick={() => setMess((value) => !value)}
                                    />
                                </Col>
                            </Row>
                        </FieldSet>
                    </CardBody>
                    <CardFooter>
                        <NButton
                            className={'text-white px-4 fw-bold float-end'}
                            color={'success'}
                            buttonText={formType === 'create' ? 'Save' : 'Update'}
                            loading={pgStore.loading}
                            onClick={handleSubmit}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    )
}

export default PgForm