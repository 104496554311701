
export const getColorByStatus = (status: string) => {
    switch (status) {
        case 'in-review':
            return 'warning'
        case 'listed':
            return 'success'
        case 'rejected':
            return 'danger'
        default:
            return 'info'
    }
}

export const unixToSeconds = (unixTime: number): number => {
    const seconds = unixTime % 60
    return seconds
}