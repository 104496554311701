import { useEffect, useState } from 'react'
import { useFlatStore } from '../../stores'
import { ListBox } from '../../components'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { useNavigate } from 'react-router-dom'
import { NDataOptionModal } from '../../monorep'

const FlatList = () => {

    const flatStore = useFlatStore()
    const navigate = useNavigate()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        flatStore.getFlat(id, () => {
            setShowOptionModal(true)
        })
    }

    // const handleRefreshButton = () => flatStore.getFlats()

    useEffect(() => {
        const timeout = setTimeout(() => flatStore.getFlats())
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          name={'Flats'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', bhk: 'Flat Type', furnishing: 'Furnishing Type', views: 'Views', wishlists: 'Wishlisted By',city: 'City', for: 'Rent/Sale', status: 'Status' }}
          data={flatStore.flats}
          meta={flatStore.meta}
          primary={['id']}
          handleRefreshButton={() => flatStore.getFlats()}
          handleAddButton={() => navigate('/property/flats/add')}
          onRowClick={(data: any) => handleRowClick(data.id)}
        >
            {showOptionModal && <NDataOptionModal
              visible={showOptionModal}
              setVisible={setShowOptionModal}
              primary={flatStore.flat.id}
              options={
                [
                    [
                        { value: titleCase(flatStore.flat.propertyStatus), color: getColorByStatus(flatStore.flat.propertyStatus), disabled: true }
                    ],
                    [
                        { title: 'Update', color: 'info', icon: 'fa-pencil', disabled: flatStore.flat.propertyStatus === 'listed' ? false : true, onClick: (id: string) => navigate('/property/flats/:id/update'.replace(':id', id)) },
                        { title: 'View', color: 'warning', icon: 'fa-eye' }
                    ]
                ]
              }
            />}
        </ListBox>
    )
}

export default FlatList