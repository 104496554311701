import { AxiosError } from 'axios';
import { APIError, CommonAPIRequestFilter } from '../../@types'
import { PgRepository, PgsListResponse, PgsResponse } from './interface';
import { APIService } from '@bigbroindia/barebone';
import { pgListMap } from './mapper';
import { PgAPIPayload } from './model';

async function getPgs (filters?: CommonAPIRequestFilter): Promise<PgsListResponse> {
    try {
        const response = await APIService.get('/a/pgs/my-properties', filters)
        return {
            data: pgListMap(response),
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Pgs list api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function getPg (id: string): Promise<PgsResponse> {
    try {
        const response = await APIService.get(`/a/pgs/${id}`)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Pgs api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function postPg (payload: PgAPIPayload): Promise<PgsResponse> {
    try {
        const response = await APIService.post(`/a/pgs`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Pg create api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

async function putPg (id: string, payload: PgAPIPayload): Promise<PgsResponse> {
    try {
        const response = await APIService.put(`/a/pgs/${id}`, payload)
        return {
            data: response,
            error: null
        }
    } catch (err) {
        const error = (err as AxiosError)?.response?.data as APIError
        console.error('Pg update api error', error)
        return {
            data: null,
            error: error.message
        }
    }
}

export const pgsRespository: PgRepository = {
    getPgs,
    getPg,
    postPg,
    putPg
}