import { Container, Dropdown, DropdownDivider, DropdownHeader, DropdownItem, DropdownMenu, DropdownToggle, Header, HeaderNav, HeaderToggler, Image } from '@nanobits/react-ui'

import Icon from '@nanobits/react-fa-icons'
import { useNavigate } from 'react-router-dom'
import { useAppAuth } from '../../../app/hook'

const GlobalHeader = () => {

  const navigation = useNavigate()
  const auth = useAppAuth()
  
  const logoutHandle = () => auth?.logout({ type: 'single' }, () => {
    navigation('/login', { replace: true })
  })

  return (
    <Header position={'sticky'} className={'mb-4'}>
      <Container fluid>
        <HeaderToggler className={'ps-1'}>
          <Icon type={'solid'} icon={'fa-bars'}/>
        </HeaderToggler>
        <HeaderNav>
          <Dropdown variant={'nav-item'}>
            <DropdownToggle className={'py-0'} caret={false}>
              <Image className={'rounded-circle'} src={'https://dummyimage.com/40x40/ccc&text=T'}/>
            </DropdownToggle>
            <DropdownMenu className={'pt-0'} placement={'bottom-end'}>
              <DropdownHeader className={'bg-primary text-white'}>{auth?.user.name}</DropdownHeader>
              <DropdownHeader className={'bg-light fw-semibold py-2'}>{'Account'}</DropdownHeader>
              <DropdownItem onClick={() => navigation('/profile')}>{'Profile'}</DropdownItem>
              {/* <DropdownItem href={'#'}>{'Updates'} <Badge color={'info'} className={'ms-2'}> 42 </Badge></DropdownItem>
              <DropdownHeader className={'bg-light fw-semibold py-2'}>{'Settings'}</DropdownHeader> */}
              <DropdownDivider />
              <DropdownItem onClick={logoutHandle}>{'logout'}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </HeaderNav>
      </Container>
    </Header>
  )
}

export default GlobalHeader

