import { NavGroup, NavItem } from "@nanobits/react-ui"

const menuItems = [
    {
        component: NavItem,
        name: "Dashboard",
        to: '/'
    },
    {
        component: NavGroup,
        name: 'Property',
        to: '/property',
        items: [
            {
                component: NavItem,
                name: 'Flats',
                to: '/property/flats'
            },
            {
                component: NavItem,
                name: 'Houses',
                to: '/property/houses'
            },
            {
                component: NavItem,
                name: 'Lands',
                to: '/property/lands'
            },
            {
                component: NavItem,
                name: 'PGs',
                to: '/property/pgs'
            }
        ]
    }
]

export default menuItems