import { create } from 'zustand'
import { MetaAPIResponse, CommonAPIRequestFilter } from '../@types'
import { LandAPIMap, LandAPIPayload, LandAPIResponse } from '../models/land/model'
import { landRepository } from '../models/land/repository'

interface LandStateInterface {
    loading: boolean
    error: null | string
    success: null | string
    lands: LandAPIMap[]
    land: LandAPIResponse
    meta: MetaAPIResponse
    filters: CommonAPIRequestFilter
}

interface LandActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    setFilter: (key: keyof CommonAPIRequestFilter, value: any) => void
    clearFilter: () => void
    getLands: (filters?: CommonAPIRequestFilter, callback?: () => void) => void
    getLand: (id: string, callback?: () => void) => void
    postLand: (payload: LandAPIPayload, callback?: () => void) => void
    putLand: (id: string, payload: LandAPIPayload, callback?: () => void) => void
}

const initial: LandStateInterface = {
    loading: false,
    error: null,
    success: null,
    lands: [] as LandAPIMap[],
    land: {} as LandAPIResponse,
    meta: {} as MetaAPIResponse,
    filters: { page: 1, records: 10 } as CommonAPIRequestFilter
}

const useLandStore = create<LandStateInterface & LandActionInterface>((set) => ({

    ...initial,

    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    setFilter: (key: keyof CommonAPIRequestFilter,value: string | number) => set((state) => ({ filters: {...state.filters ,[key]: value} })),
    clearFilter: () => set({ filters: {...initial.filters} }),

    getLands: async (filters?: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await landRepository.getLands(filters)
        if (response.data) {
            set({ lands: response.data.list, meta: response.data.meta })
            if ( callback ) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getLand: async (id: string, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await landRepository.getLand(id)
        if (response.data) {
            set({ land: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postLand: async (payload: LandAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await landRepository.postLand(payload)
        if (response.data) {
            set({ success: 'landCreateSuccess' })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    putLand: async (id: string, payload: LandAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await landRepository.putLand(id, payload)
        if (response.data) {
            set({ success: 'landUpdateSuccess' })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default useLandStore