import { useEffect, useState } from 'react'
import { ListBox } from '../../components'
import { DataOptionModal } from '@nanobits/react-components'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { usePgStore } from '../../stores'
import { useNavigate } from 'react-router-dom'

const PgsList = () => {

    const navigate = useNavigate()

    const pgStore = usePgStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        pgStore.getPg(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => pgStore.getPgs())
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          name={'PGs'}
          setCurrentPage={setCurrentPage}
          columns={{ name: 'Name', seater: 'Seater', rent: 'Rent', security: 'Security',city: 'City', views: 'Views' ,wishlists: 'Wishlisted By', status: 'Status' }}
          data={pgStore.pgs}
          meta={pgStore.meta}
          primary={['id']}
          handleRefreshButton={() => pgStore.getPgs()}
          onRowClick={(data: any) => handleRowClick(data.id)}
          handleAddButton={() => navigate('/property/pgs/add')}
        >
            {showOptionModal && <DataOptionModal
              visible={showOptionModal}
              setVisible={setShowOptionModal}
              primary={pgStore.pg.id}
              options={
                [
                    [
                        { value: titleCase(pgStore.pg.propertyStatus), color: getColorByStatus(pgStore.pg.propertyStatus), disabled: true }
                    ],
                    [
                        { title: 'Update', color: 'info', icon: 'fa-pencil', disabled: pgStore.pg.propertyStatus === 'listed' ? false : true, onClick: (id: string) => navigate('/property/pgs/:id/update'.replace(':id', id)) },
                        { title: 'View', color: 'warning', icon: 'fa-eye' }
                    ]
                ]
              }
            />}
        </ListBox>
    )
}

export default PgsList