import { create } from 'zustand'
import { MetaAPIResponse, CommonAPIRequestFilter } from '../@types'
import { PgAPIMap, PgAPIPayload, PgAPIResponse } from '../models/pgs/model'
import { pgsRespository } from '../models/pgs/respository'

interface PgStateInterface {
    loading: boolean
    error: null | string
    success: null | string
    pgs: PgAPIMap[]
    pg: PgAPIResponse
    meta: MetaAPIResponse
    filters: CommonAPIRequestFilter
}

interface PgActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    setFilter: (key: keyof CommonAPIRequestFilter, value: any) => void
    clearFilter: () => void
    getPgs: (filters?: CommonAPIRequestFilter, callback?: () => void) => void
    getPg: (id: string, callback?: () => void) => void
    postPg: (payload: PgAPIPayload, callback?: () => void) => void
    putPg: (id: string, payload: PgAPIPayload, callback?: () => void) => void
}

const initial: PgStateInterface = {
    loading: false,
    error: null,
    success: null,
    pgs: [] as PgAPIMap[],
    pg: {} as PgAPIResponse,
    meta: {} as MetaAPIResponse,
    filters: { page: 1, records: 10 } as CommonAPIRequestFilter
}

const usePgStore = create<PgStateInterface & PgActionInterface>((set) => ({

    ...initial,

    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    setFilter: (key: keyof CommonAPIRequestFilter,value: string | number) => set((state) => ({ filters: {...state.filters ,[key]: value} })),
    clearFilter: () => set({ filters: {...initial.filters} }),

    getPgs: async (filters?: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await pgsRespository.getPgs(filters)
        if (response.data) {
            set({ pgs: response.data.list, meta: response.data.meta })
            if ( callback ) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getPg: async (id: string, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await pgsRespository.getPg(id)
        if (response.data) {
            set({ pg: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postPg: async (payload: PgAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await pgsRespository.postPg(payload)
        if (response.data) {
            set({ success: 'pgCreateSuccess' })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    putPg: async (id: string, payload: PgAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await pgsRespository.putPg(id, payload)
        if (response.data) {
            set({ success: 'pgUpdateSuccess' })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default usePgStore