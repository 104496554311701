import { create } from 'zustand'
import { FlatAPIMap, FlatAPIPayload, FlatAPIResponse } from '../models/flats/model'
import { CommonAPIRequestFilter, MetaAPIResponse } from '../@types'
import { flatRepository } from '../models/flats/repository'
import { toast } from 'react-toastify'

interface FlatStateInterface {
    loading: boolean
    error: null | string
    success: null | string
    flats: FlatAPIMap[]
    flat: FlatAPIResponse
    meta: MetaAPIResponse
    filters: CommonAPIRequestFilter
}

interface FlatActionInterface {
    setLoading: (loading: boolean) => void
    setError: (value: string | null) => void
    setFilter: (key: keyof CommonAPIRequestFilter, value: any) => void
    clearFilter: () => void
    getFlats: (filters?: CommonAPIRequestFilter, callback?: () => void) => void
    getFlat: (id: string, callback?: () => void) => void
    postFlat: (payload: FlatAPIPayload, callback?: () => void) => void
    putFlat: (id: string, payload: FlatAPIPayload, callback?: () => void) => void
}

const initial: FlatStateInterface = {
    loading: false as boolean,
    error: null,
    success: null,
    flats: [] as FlatAPIMap[],
    flat: {} as FlatAPIResponse,
    meta: {} as MetaAPIResponse,
    filters: { page: 1, records: 10 } as CommonAPIRequestFilter
}

const useFlatStore = create<FlatStateInterface & FlatActionInterface>((set) => ({

    ...initial,

    setLoading: (loading: boolean) => set(() => ({ loading: loading })),
    setError: (value: string | null) => set(() => ({ error: value })),
    setFilter: (key: keyof CommonAPIRequestFilter,value: string | number) => set((state) => ({ filters: {...state.filters ,[key]: value} })),
    clearFilter: () => set({ filters: {...initial.filters} }),

    getFlats: async (filters?: CommonAPIRequestFilter, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await flatRepository.getFlatsList(filters)
        if (response.data) {
            set({ flats: response.data.list, meta: response.data.meta })
            if ( callback ) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    getFlat: async (id: string, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await flatRepository.getFlat(id)
        if (response.data) {
            set({ flat: response.data })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    postFlat: async (payload: FlatAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await flatRepository.postFlat(payload)
        if (response.data) {
            set({ success: 'flatCreateSuccess' })
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    },

    putFlat: async (id: string, payload: FlatAPIPayload, callback?: () => void) => {
        set({ loading: true, error: null })
        const response = await flatRepository.putFlat(id, payload)
        if (response.data) {
            set({ success: 'flatUpdateSuccess' })
            toast.success('Flat updated successfully!')
            if (callback) callback()
        } else {
            set({ error: response.error })
        }
        set({ loading: false })
    }
}))

export default useFlatStore