import { Suspense } from 'react'
import { WaitingLoader } from '../layout/theme/fallback'
import { GlobalBody } from '../layout/theme/global'
import { FlatForm, FlatList, HouseForm, HouseList, LandForm, LandList, PgsList, PgForm } from '../features'

interface PropertyPageInterface {
    property_type: 'flat' | 'house' | 'land' | 'pg'
    page_type: 'list' | 'form'
    form_type?: 'create' | 'update'
}

const PropertyPage = ({
    property_type,
    page_type,
    form_type
}: PropertyPageInterface) => {
    return (
        <Suspense fallback={<WaitingLoader/>}>
            <GlobalBody>
                { (property_type === 'flat' && page_type === 'list') && <FlatList/> }
                { (property_type === 'flat' && page_type === 'form') && <FlatForm formType={form_type} /> }
                { (property_type === 'house' && page_type === 'list') && <HouseList/> }
                { (property_type === 'house' && page_type === 'form') && <HouseForm formType={form_type} /> }
                { (property_type === 'land' && page_type === 'list') && <LandList/> }
                { (property_type === 'land' && page_type === 'form') && <LandForm formType={form_type} /> }
                { (property_type === 'pg' && page_type === 'list') && <PgsList/>  }
                { (property_type === 'pg' && page_type === 'form') && <PgForm formType={form_type} />  }
            </GlobalBody>
        </Suspense>
    )
}

export default PropertyPage