import { useEffect, useState } from 'react'
import { ListBox } from '../../components'
import { NDataOptionModal } from '../../monorep'
import { titleCase } from '@bigbroindia/helper'
import { getColorByStatus } from '../../helpers/shorthand'
import { useLandStore } from '../../stores'
import { useNavigate } from 'react-router-dom'

const LandList = () => {

    const navigate = useNavigate()

    const landStore = useLandStore()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showOptionModal, setShowOptionModal] = useState<boolean>(false)

    const handleRowClick = async (id: string) => {
        landStore.getLand(id, () => {
            setShowOptionModal(true)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => landStore.getLands())
        return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ListBox
          name={'Lands'}
          setCurrentPage={setCurrentPage}
          columns={{ city: 'City', area: 'Size', views: 'Views', wishlists: 'Wishlists', status: 'Status' }}
          data={landStore.lands}
          meta={landStore.meta}
          primary={['id']}
          handleRefreshButton={() => landStore.getLands()}
          onRowClick={(data: any) => handleRowClick(data.id)}
          handleAddButton={() => navigate('/property/lands/add')}
        >
            {showOptionModal && <NDataOptionModal
              visible={showOptionModal}
              setVisible={setShowOptionModal}
              primary={landStore.land.id}
              options={
                [
                    [
                        { value: titleCase(landStore.land.propertyStatus), color: getColorByStatus(landStore.land.propertyStatus), disabled: true }
                    ],
                    [
                        { title: 'Update', color: 'info', icon: 'fa-pencil', disabled: landStore.land.propertyStatus === 'listed' ? false : true, onClick: (id: string) => navigate('/property/lands/:id/update'.replace(':id', id)) },
                        { title: 'View', color: 'warning', icon: 'fa-eye' }
                    ]
                ]
              }
            />}
        </ListBox>
    )
}

export default LandList