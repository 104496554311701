import { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "@nanobits/react-ui"
import { DescriptionInput, FieldSet, NTextInput, SelectInput } from "../../monorep"
import { NButton } from "@nanobits/react-components"
import { titleCase } from "@bigbroindia/helper"
import { useStateStore, useCityStore, useHouseStore } from "../../stores"
import { useNavigate } from "react-router-dom"
import { HouseAPIPayload } from "../../models/house/model"

interface HouseFormInterface {
  formType?: 'create' | 'update'
}

const HouseForm = ({
  formType = 'create'
}: HouseFormInterface) => {

  const navigate = useNavigate()

  const stateStore = useStateStore()
  const cityStore = useCityStore()
  const houseStore = useHouseStore()

  const [type, setType] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [price, setPrice] = useState<number>()
  const [aop, setAop] = useState<number>()
  const [bedroom, setBedroom] = useState<number>()
  const [bathroom, setBathroom] = useState<number>()
  const [balcony, setBalcony] = useState<number>()
  const [floor, setFloor] = useState<number> ()
  const [description, setDescription] = useState<string | undefined>()
  const [carpet_area, setCarpetArea] = useState<number>()
  const [project_name, setProjectName] = useState<string>()


  const [state_id, setStateId] = useState<number>()
  const [city_id, setCityId] = useState<number>()

  const [furnishing, setFurnishing] = useState<'unfurnished' | 'semi-furnished' | 'furnished'>('unfurnished')
  const [bachelor_friendly, setBachelorFriendely] = useState<boolean>(false)
  const [parking, setParking] = useState<boolean>(false)

  const handleFurnishingOption = (option: 'color' | 'value', type: typeof furnishing): any => {
    switch (type) {
      case 'furnished':
        if (option === 'color') return 'success'
        setFurnishing('unfurnished')
        break
      case 'unfurnished':
        if (option === 'color') return 'danger'
        setFurnishing('semi-furnished')
        break
      case 'semi-furnished':
        if (option === 'color') return 'warning'
        setFurnishing('furnished')
        break
      default:
        break
    }
  }

  const handleSelectState = (value: number) => {
    setCityId(undefined)
    setStateId(value)
    cityStore.getCitiesDropdown({ state_id: value })
  }

  const handleSubmit = async () => {
    const postData: HouseAPIPayload = {
      city_id: Number(city_id),
      address: String(address),
      price: Number(price),
      furnishing,
      parking,
      aop: Number(aop),
      bedroom: Number(bedroom),
      bathroom: Number(bathroom),
      balcony: Number(balcony),
      floors: Number(floor),
      description: String(description),
      carpet_area: Number(carpet_area),
      project_name: String(project_name),
      type: String(type),
      bachelor_friendly
    }
    if (formType === 'create') {
      houseStore.postHouse(postData, () => navigate('/property/houses'))
    } else {
      houseStore.putHouse(houseStore.house.id, postData, () => navigate('/property/houses') )
    }
  }

  useEffect(() => {
    if (formType === 'update' && houseStore.house.id === undefined) {
      navigate('/property/houses')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseStore.house.id, formType])

  useEffect(() => {
    const timeout = setTimeout(() => stateStore.getStatesDropdown({ country_id: 1 }))
    return () => clearTimeout(timeout)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formType === 'update') {
      cityStore.getCitiesDropdown({ state_id: state_id })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id, formType])

  useEffect(() => {
    if (formType === 'update') {
      setFurnishing(houseStore.house.furnishing)
      setBachelorFriendely(houseStore.house.bachelorFriendly)
      setParking(houseStore.house.parking)
      setType(houseStore.house.type)
      setStateId(houseStore.house.city.stateId)
      setCityId(houseStore.house.cityId)
      setAddress(houseStore.house.address)
      setProjectName(houseStore.house.projectName)
      setAop(Number(houseStore.house.aop))
      setPrice(Number(houseStore.house.price))
      setBathroom(houseStore.house.bathroom)
      setBedroom(houseStore.house.bedroom)
      setBalcony(houseStore.house.balcony)
      setFloor(houseStore.house.floors)
      setCarpetArea(Number(houseStore.house.carpetArea))
      setDescription(String(houseStore.house.description))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseStore, formType])

  return (
    <Row>
      <Col md={12}>
        <Card
        >
          <CardHeader className={'bg-primary text-white'} >
            {formType === 'create' ? 'Create House' : `Update House: ${houseStore.house.projectName}`}&nbsp;
            <NButton
                    className={'float-end mx-1 btn-success text-white'}
                    size={'sm'}
                    buttonIconLeft={'fa fa-bars'}
                    onClick={() => navigate('/property/houses')}
                    loading={houseStore.loading}
                />
          </CardHeader>
          <CardBody>
            <FieldSet
              label={'Basic Information'}
            >
              <Row>
                <Col md={4} className={'mb-3'} >
                  <SelectInput
                    name={'for'}
                    label={'Property For'}
                    options={[{ value: 'sale', label: 'Sale' }, { value: 'rent', label: 'Rent' }]}
                    value={type}
                    onUpdate={(value) => setType(String(value))}
                    required
                  />
                </Col>
              </Row>
            </FieldSet>
            <FieldSet
              label={'Location Information'}
            >
              <Row>
                <Col className={'mb-3'} md={6}>
                  <SelectInput
                    name={'state'}
                    label={'State'}
                    placeholder={'Select state'}
                    options={stateStore.dropdown}
                    value={state_id}
                    onUpdate={(value) => handleSelectState(Number(value))}
                    required
                  />
                </Col>
                <Col className={'mb-3'} md={6}>
                  <SelectInput
                    name={'city'}
                    label={'City'}
                    placeholder={'Select city'}
                    options={cityStore.dropdown}
                    onUpdate={(value) => setCityId(Number(value))}
                    value={city_id}
                    required
                  />
                </Col>
                <Col className={'mb-3'} md={12}>
                  <DescriptionInput
                    name={'address'}
                    label={'Address'}
                    placeholder={'Enter property address'}
                    value={address}
                    onUpdate={setAddress}
                    required
                  />
                </Col>
              </Row>
            </FieldSet>
            <FieldSet
              label={'Property Information'}
            >
              <Row>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'project_name'}
                    label={'Project Name'}
                    placeholder={'Enter project name'}
                    required
                    value={project_name}
                    onUpdate={setProjectName}
                  />
                </Col>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'aop'}
                    label={'AOP'}
                    placeholder={'Enter age of property'}
                    required
                    value={aop}
                    onUpdate={setAop}
                  />
                </Col>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'price'}
                    label={'Price'}
                    placeholder={'Enter property price'}
                    required
                    value={price}
                    onUpdate={setPrice}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'bedroom'}
                    label={'Bedroom'}
                    placeholder={'Enter number of bedrooms'}
                    required
                    value={bedroom}
                    onUpdate={setBedroom}
                  />
                </Col>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'bathroom'}
                    label={'Bathroom'}
                    placeholder={'Enter number of bathrooms'}
                    required
                    value={bathroom}
                    onUpdate={setBathroom}
                  />
                </Col>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'balcony'}
                    label={'Balcony'}
                    placeholder={'Enter number of balconies'}
                    required
                    value={balcony}
                    onUpdate={setBalcony}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'floor'}
                    label={'Floor'}
                    placeholder={'Enter flat floor'}
                    required
                    value={floor}
                    onUpdate={setFloor}
                  />
                </Col>
                <Col className={'mb-3'} md={4}>
                  <NTextInput
                    name={'carpet_area'}
                    label={'Carpet Area'}
                    placeholder={'Enter flat carpet area'}
                    value={carpet_area}
                    onUpdate={setCarpetArea}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className={'mb-3'}>
                  <DescriptionInput
                    name={'description'}
                    label={'Description'}
                    placeholder={'Enter property description'}
                    value={description}
                    onUpdate={setDescription}
                  />
                </Col>
              </Row>
            </FieldSet>
            <FieldSet
              label={'Amenities Information'}
            >
              <Row>
                <Col md={4} className={'mb-3'}>
                  <NButton
                    buttonText={'Bachelor Friendly'}
                    color={bachelor_friendly ? 'success' : 'danger'}
                    className={`text-white w-100 ${bachelor_friendly ? '' : 'text-decoration-line-through'}`}
                    onClick={() => setBachelorFriendely((value) => !value)}
                  />
                </Col>
                <Col md={4} className={'mb-3'}>
                  <NButton
                    className={'w-100 text-white'}
                    buttonText={titleCase(furnishing)}
                    color={handleFurnishingOption('color', furnishing)}
                    onClick={() => handleFurnishingOption('value', furnishing)}
                  />
                </Col>
                <Col md={4} className={'mb-3'}>
                  <NButton
                    buttonText={'Parking'}
                    color={parking ? 'success' : 'danger'}
                    className={`text-white w-100 ${parking ? '' : 'text-decoration-line-through'}`}
                    onClick={() => setParking((value) => !value)}
                  />
                </Col>
              </Row>
            </FieldSet>
          </CardBody>
          <CardFooter>
            <NButton
              className={'text-white px-4 fw-bold float-end'}
              color={'success'}
              buttonText={formType === 'create' ? 'Save' : 'Update'}
              loading={houseStore.loading}
              onClick={handleSubmit}
            />
          </CardFooter>
        </Card>
      </Col>
    </Row>
  )
}

export default HouseForm